.u-show-tooltip-magnifier {
  text-align: left;
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 0;
  box-shadow: 2px 2px 10px rgba(0,0,0,0.5);
  background-color: rgba(255,255,255,0.8);
  color: black;
  overflow: visible;
  z-index: 100;
}
.u-hide-tooltip-magnifier {
  display: none;
}