.u-mark-x {
  position: absolute;
  display: inline-block;
  height: 100%;
  cursor: pointer;
}

.u-annotation-mark {
  opacity: 0.3
}

.u-mark-x-label {
  position: absolute;
  padding: 0 1%;
  background: #fff !important;
}

.u-mark-x:hover {
  border-left-style: solid !important;
  /*	border-right-style: solid !important;*/
}

.u-mark-x:hover .u-mark-x-label {
  border-style: solid !important;
}
